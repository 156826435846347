import axios from "axios";
export const propertyMaster = {
  data() {
    return {
      propertyMastersList: [],
      propertyMastersWithAllList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async getpropertyMasterListMethod() {
      try {
        let data = JSON.stringify({});
        console.log("Chweck Where it is coming", window.location.hostname);
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicPropertyMasterRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        let result = await axios(config);
        console.log('check URL',this.getDynamicPropertyMasterRestAPIUrlMethod())
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.propertyMastersList = result.data.data.Items.map(
            (commonObj) => commonObj.project_type_name
          );
          for (const propertyMaster of this.propertyMastersList) {
            this.propertyMastersWithAllList.push(propertyMaster);
          }
          this.propertyMastersWithAllList.unshift("All");
        }
      } catch (error) {
        throw error;
      }
    },
    getDynamicPropertyMasterRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com" ||  window.location.hostname == 'localhost'
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/listprojecttypes"
        : window.location.hostname === "test.commdealdata.com" 
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/listprojecttypes"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/listprojecttypes"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/listprojecttypes";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com" ||  window.location.hostname == 'localhost'
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com" 
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com"
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
