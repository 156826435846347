<template>
  <v-app :style="{ background: customBackgroundColor }">
    <ContactUsDialog :ContactUsDialog="ContactUsDialog" :currentTeamDetailsObject="currentTeamDetailsObject" @clicked="ContactUsDialogEmit" />
    <v-main>
      <Snackbar :SnackBarComponent="SnackBarComponent" />
      <Overlay :overlay="overlay" />
      <v-row no-gutters class="fill-height" align="center" justify="center">
        <v-col cols="12" md="12" align="center">
          <v-card-text>
            <v-card v-if="current_view == 'CARD'" class="InvitePageCard elevation-0 pa-8 overflow-y-auto">
              <div class="InvitePageLabelVariant1">DealData</div>
              <div class="InvitePageLabelVariant2 mt-n12">Fast, Secure, and Convenient Deal Submission & Analysis</div>

              <v-btn dark class="InvitePageButtonVariant1 text-capitalize" @click="enterStepOneMethod()"> Sign Up </v-btn>
              <v-row class="space-around mt-n4">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="InvitePageCardVariant1">
                    <v-img contain class="ImageHeightVariant1" src="@/assets/Money.svg"></v-img>
                    <div class="InvitePageLabelVariant3">Submit Deals Fast</div>
                    <div class="InvitePageLabelVariant4">Submit deal-level information quickly through our step-by-step entry process or via bulk upload.</div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="InvitePageCardVariant1">
                    <v-img contain class="ImageHeightVariant2" src="@/assets/Security.svg"></v-img>
                    <div class="InvitePageLabelVariant3">Aggregate and Individual Deal Analysis</div>
                    <div class="InvitePageLabelVariant4">Review and analyze deal information on an individual and aggregate level.</div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="InvitePageCardVariant1">
                    <v-img contain class="ImageHeightVariant3" src="@/assets/Phone.svg"></v-img>
                    <div class="InvitePageLabelVariant3">Secure and Confidential</div>
                    <div class="InvitePageLabelVariant4 mb-8">Security and confidentiality built from the ground up.</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card align="center" color="transparent" :height="cardHeight" v-if="current_view == 'STEPPER'" class="elevation-0 overflow-y-auto">
              <v-card class="pa-8" max-width="500px">
                <div v-if="loadingActivatingAccount == false" class="text-left fontSize30px fontWeightVariant1 lineHeightVariant2 black--text">
                  {{ currentStepperObject.stepper_title }}
                </div>
                <div v-if="loadingActivatingAccount == true" class="text-left fontSize30px fontWeightVariant1 lineHeightVariant2 black--text">One moment please</div>
                <div v-if="loadingActivatingAccount == false && currentStepperObject.stepper_sub_title != ''" class="text-left fontSize16px greyColorVariant1--text mt-2">
                  {{ currentStepperObject.stepper_sub_title }}
                </div>
                <v-window v-model="loginStepper" align="left">
                  <v-window-item :value="1">
                    <v-form class="mt-4" ref="formStep1">
                      <div class="font-weight-bold mb-2">First Name <span class="red--text">*</span></div>
                      <!--(v) => /^[a-zA-Z]*$/.test(v) || 'only Alphabets',-->
                      <v-text-field
                        dense
                        outlined
                        v-model="user_details.bank_contact_person_first_name"
                        :rules="[(v) => !!v || 'Required', (v) => /^[a-zA-Z ]*$/.test(v) || 'No special characters allowed']"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                      <!--(v) => /^[a-zA-Z]*$/.test(v) || 'only Alphabets',-->
                      <div class="font-weight-bold mb-2 mt-n2">Last Name <span class="red--text">*</span></div>
                      <v-text-field
                        dense
                        outlined
                        v-model="user_details.bank_contact_person_last_name"
                        :rules="[(v) => !!v || 'Required', (v) => /^[a-zA-Z ]*$/.test(v) || 'No special characters allowed']"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Email Address <span class="red--text">*</span></div>
                      <v-text-field dense filled readonly outlined v-model="user_details.user_email_id" :rules="[(v) => !!v || 'Required']" class="borderRadiusVariant1"></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Contact Number <span class="red--text">*</span></div>
                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="5">
                          <v-select
                            outlined
                            dense
                            filled
                            :items="GetAllCountriesList"
                            item-value="country_code"
                            item-text="country_dial_code"
                            class="borderRadiusVariant1 mr-2"
                            label="Country Code"
                            :rules="[(v) => !!v || 'Required']"
                            v-model="user_details.bank_contact_person_country_code"
                          >
                            <template slot="selection" slot-scope="{ item }">
                              {{ item.country_dial_code }}
                            </template>
                            <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_dial_code }}) </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="7">
                          <v-text-field
                            ref="ContactNUmberForm"
                            dense
                            outlined
                            class="borderRadiusVariant1"
                            :rules="[(v) => !!v || 'Required', (v) => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Invalid phone number format (e.g., (xxx) xxx-xxxx)']"
                            v-model="user_details.bank_contact_person_contact_number"
                            @input="UserformatPhoneNumber"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <div class="font-weight-bold mb-2 mt-n2">Bank Name <span class="red--text">*</span></div>
                      <v-text-field dense outlined readonly filled class="borderRadiusVariant1" v-model="user_details.user_bank_name"></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Create Password <span class="red--text">*</span></div>
                      <v-text-field
                        dense
                        outlined
                        :type="isPwdLoginCreate ? 'text' : 'password'"
                        v-model="user_details.user_create_password"
                        @click:append="isPwdLoginCreate = !isPwdLoginCreate"
                        :append-icon="isPwdLoginCreate ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 8) || 'Password Must Have Min. 8 characters',
                          (v) => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(v) || 'Requires Special Character',
                        ]"
                        placeholder="8 Characters"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Confirm Password <span class="red--text">*</span></div>
                      <v-text-field
                        dense
                        outlined
                        :type="isPwdLoginConfirm ? 'text' : 'password'"
                        v-model="user_details.user_confirm_password"
                        @click:append="isPwdLoginConfirm = !isPwdLoginConfirm"
                        :append-icon="isPwdLoginConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 8) || 'Password Must Have Min. 8 characters',
                          (v) => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(v) || 'Requires Special Character',
                        ]"
                        placeholder="8 Characters"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                    </v-form>
                    <v-card-actions>
                      <v-btn color="primary" depressed :loading="loadingStepper1" @click="validateStepperOneMethod()" class="text-capitalize borderRadiusVariant2 pa-6"> Activate </v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-form class="mt-4" ref="formStep2">
                      <div class="font-weight-bold mb-2">Verification Code <span class="red--text">*</span></div>
                      <v-otp-input
                        :length="6"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 6) || 'Password Must Have Min. 6 characters',
                          (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                        ]"
                        v-model="verification_code_details.verification_code"
                      ></v-otp-input>
                      <!-- <v-text-field
                        dense
                        outlined
                        v-model="verification_code_details.verification_code"
                        maxLength="6"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) =>
                            (v && v.length >= 6) ||
                            (v && v.length <= 6) ||
                            'Password Must Have Min. 6 characters',
                          (v) =>
                            /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(
                              v
                            ) || 'Numeric only',
                        ]"
                        placeholder="6 Digit Code"
                        class="borderRadiusVariant1"
                      ></v-text-field> -->
                      <!-- <div v-if="ResendOTPCounter !== 0" class="">Resend OTP available in {{ ResendOTPCounter }}</div>
                      <v-btn
                        text
                        v-if="ResendOTPCounter === 0"
                        :loading="loadingStepper1"
                        class="text-decoration-underline pa-0 ml-0"
                        @click="validateStepperOneMethod()"
                        >Resend OTP</v-btn
                      > -->
                    </v-form>
                    <v-card-actions class="pa-0 ma-0">
                      <v-btn color="primary" block depressed :loading="loadingStepper2" @click="validateStepperTwoMethod()" class="text-capitalize pa-6"> Verify </v-btn>
                    </v-card-actions>
                    <v-row no-gutters class="mt-2">
                      <v-col cols="12" align="center">
                        <v-btn
                          text
                          :disabled="ResendOTPCounter !== 0"
                          :loading="loadingStepper1"
                          class="pa-0 ml-0"
                          :class="ResendOTPCounter === 0 ? 'text-decoration-underline' : ''"
                          @click="validateStepperOneMethod()"
                          >Resend OTP <span v-if="ResendOTPCounter !== 0" class="pl-2">{{ ResendOTPCounter }}</span></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-card v-if="!loadingActivatingAccount" class="pa-6 mt-6 elevation-0" color="grey lighten-4">
                      <div class="fontSize15px mb-2">Bank Holding Company Name</div>
                      <div class="font-weight-bold fontSize18px" v-if="currentTeamDetailsObject.bank_name">
                        {{ currentTeamDetailsObject.bank_name }}
                      </div>
                      <div v-else class="font-weight-bold fontSize18px">Not Assigned</div>
                      <div class="fontSize15px my-2">Total Assets($000s)</div>
                      <div class="font-weight-bold fontSize18px" v-if="currentTeamDetailsObject.bank_total_assets != undefined">
                        {{ currentTeamDetailsObject.bank_total_assets }}
                      </div>
                      <div v-else class="font-weight-bold fontSize18px">Not Assigned</div>
                      <div class="fontSize15px my-2">State in Footprint</div>
                      <div class="font-weight-bold fontSize18px" v-if="currentTeamDetailsObject.bank_footprint_states">
                        {{ currentTeamDetailsObject.bank_footprint_states.join(",").replaceAll(",", ", ") }}
                      </div>
                      <div v-else class="font-weight-bold fontSize18px">Not Assigned</div>
                      <v-card outlined class="pa-4 mt-3">
                        <div>
                          <v-icon>mdi-alert</v-icon>
                          Something not right ?<span class="primary--text font-weight-bold cursorPointer" @click="ContactUsDialog = true"> Contact Us</span>
                        </div>
                      </v-card>
                    </v-card>
                    <v-card-actions v-if="!loadingActivatingAccount" class="mt-3">
                      <v-btn color="primary" depressed @click="validateStepperThreeMethod()" class="text-capitalize borderRadiusVariant2 pa-6"> Continue </v-btn>
                    </v-card-actions>
                    <v-card height="200px" color="grey lighten-4" class="mt-6 elevation-0" align="center" v-if="loadingActivatingAccount">
                      <v-progress-circular indeterminate color="primary" size="100" width="10" class="mt-6"></v-progress-circular>
                      <div class="primary--text font-weight-bold text-center mt-2">Activating your account.</div>
                    </v-card>
                  </v-window-item>
                  <v-window-item :value="4">
                    <v-card class="mt-6 elevation-0">
                      <div class="font-weight-bold fontSize20px">Contact {{ contact_count + 1 }}</div>
                      <v-form class="mt-4" ref="formStep4">
                        <div class="font-weight-bold mb-2">First Name <span class="red--text">*</span></div>
                        <v-text-field dense outlined v-model="contact_details.contact_first_name"  :rules="[(v) => !!v || 'Required', (v) => /^[a-zA-Z ]*$/.test(v) || 'No special characters allowed']" class="borderRadiusVariant1"></v-text-field>

                        <div class="font-weight-bold mt-n2 mb-2">Last Name <span class="red--text">*</span></div>
                        <v-text-field dense outlined v-model="contact_details.contact_last_name"  :rules="[(v) => !!v || 'Required', (v) => /^[a-zA-Z ]*$/.test(v) || 'No special characters allowed']" class="borderRadiusVariant1"></v-text-field>

                        <div class="font-weight-bold mt-n2 mb-2">Title <span class="red--text">*</span></div>
                        <v-text-field dense outlined v-model="contact_details.contact_title" :rules="[(v) => !!v || 'Required']" class="borderRadiusVariant1"></v-text-field>

                        <!--  (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                              ) || 'Invalid Email',-->
                        <div class="font-weight-bold mt-n2 mb-2">Email <span class="red--text">*</span></div>
                        <v-text-field
                          dense
                          outlined
                          v-model="contact_details.contact_email_id"
                          :rules="[(v) => !!v || 'Required', (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid Email']"
                          class="borderRadiusVariant1"
                        ></v-text-field>
                        <div class="font-weight-bold mb-2 mt-n2">Contact Number <span class="red--text">*</span></div>
                        <v-row no-gutters>
                          <v-col cols="12" xs="12" sm="12" md="5">
                            <v-select
                              outlined
                              dense
                              :items="GetAllCountriesList"
                              item-value="country_code"
                              item-text="country_dial_code"
                              class="borderRadiusVariant1 mr-2"
                              label="Country Code"
                              :rules="[(v) => !!v || 'Required']"
                              v-model="contact_details.contact_country_code"
                            >
                              <template slot="selection" slot-scope="{ item }">
                                {{ item.country_dial_code }}
                              </template>
                              <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_dial_code }}) </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" xs="12" sm="12" md="7">
                            <v-text-field
                              dense
                              outlined
                              class="borderRadiusVariant1"
                              :rules="
                                contact_details.contact_phone_number && contact_details.contact_phone_number != ''
                                  ? [(v) => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Invalid phone number format (e.g., (xxx) xxx-xxxx)']
                                  : []
                              "
                              v-model="contact_details.contact_phone_number"
                              @input="formatPhoneNumberMethod"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div class="font-weight-bold mt-n2 mb-2">Address Line 1 <span class="red--text">*</span></div>
                        <v-text-field dense outlined v-model="contact_details.contact_address_line_1" :rules="[(v) => !!v || 'Required']" class="borderRadiusVariant1"></v-text-field>
                        <div class="font-weight-bold mt-n2 mb-2">Address Line 2</div>
                        <v-text-field dense outlined v-model="contact_details.contact_address_line_2" class="borderRadiusVariant1"></v-text-field>
                        <div class="font-weight-bold mt-n2 mb-2">City <span class="red--text">*</span></div>
                        <v-text-field dense outlined v-model="contact_details.contact_city" @input="PreventSpaceMethod" :rules="[(v) => !!v || 'Required']" class="borderRadiusVariant1"></v-text-field>

                        <div class="font-weight-bold mt-n2 mb-2">State <span class="red--text">*</span></div>
                        <v-autocomplete
                          dense
                          outlined
                          :items="USStatesList"
                          item-text="abbreviation"
                          item-value="abbreviation"
                          v-model="contact_details.contact_state"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                        ></v-autocomplete>

                        <div class="font-weight-bold mt-n2 mb-2">Zip Code <span class="red--text">*</span></div>
                        <v-text-field
                          dense
                          outlined
                          v-model="contact_details.contact_zip_code"
                          :rules="[(v) => !!v || 'ZIP Code is required', (v) => /^[0-9]{5}$/.test(v) || 'ZIP Code must be 5 digits']"
                          class="borderRadiusVariant1"
                        ></v-text-field>
                      </v-form>
                      <v-card-actions>
                        <v-btn v-if="IsValidated || contact_count == syndication_contact_details.length - 1" text color="primary" class="text-capitalize" @click="validateStepperFourMethod('STAY')">
                          <div>Add additional contact</div>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn v-if="syndication_contact_details.length - 1 == contact_count" text small @click="validateStepperFourMethod('DELETE')">Delete Contact</v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-card-actions>
                      <v-btn color="primary" depressed @click="validateStepperFourMethod('NEXT')" class="text-capitalize borderRadiusVariant2 pa-6"> Submit </v-btn>
                      <v-spacer />
                      <v-btn v-if="contact_count > 0" text small color="primary" depressed @click="SyndicationContactMethod('BACK')"><v-icon>mdi-arrow-left-thin</v-icon> Back</v-btn>
                      <v-btn v-if="contact_count < syndication_contact_details.length - 1" text small color="primary" depressed @click="SyndicationContactMethod('FORWARD')">
                        Next<v-icon>mdi-arrow-right</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="5">
                    <v-card class="mt-6 elevation-0">
                      <v-form class="mt-4" ref="formStep5">
                        <div class="font-weight-bold mb-2">Hold Range <span class="red--text">*</span></div>
                        <v-select
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_hold_range"
                          :rules="[(v) => !!v || 'Required']"
                          :items="holdRangeItems"
                          class="borderRadiusVariant1"
                          multiple
                        >
                          <template v-slot:selection="{ index }">
                            <span v-if="index === 0" class="grey--text text-caption">
                              {{ bank_purchasing_preferences_details.purchase_hold_range.length }}
                              Selected
                            </span>
                          </template>
                        </v-select>

                        <div class="fontSize25px font-weight-bold mb-2">CRE :</div>
                        <div class="font-weight-bold my-2">Project Type <span class="red--text">*</span></div>
                        <v-select
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_project_type"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                          :items="propertyMastersList"
                          multiple
                        >
                          <template v-slot:selection="{ index }">
                            <span v-if="index === 0" class="grey--text text-caption">
                              {{ bank_purchasing_preferences_details.purchase_project_type.length }}
                              Selected
                            </span>
                          </template>
                        </v-select>
                        <div class="font-weight-bold mt-n2 mb-2">Property Class <span class="red--text">*</span></div>
                        <v-select
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_property_class"
                          :items="classMastersList"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                          multiple
                        >
                          <template v-slot:selection="{ index }">
                            <span v-if="index === 0" class="grey--text text-caption">
                              {{ bank_purchasing_preferences_details.purchase_property_class.length }}
                              Selected
                            </span>
                          </template>
                        </v-select>
                        <div class="fontSize25px font-weight-bold my-2">C&I :</div>
                        <div class="font-weight-bold mb-2">Purpose <span class="red--text">*</span></div>
                        <v-select
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_purpose"
                          :items="purposeMastersList"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                          multiple
                        >
                          <template v-slot:selection="{ index }">
                            <span v-if="index === 0" class="grey--text text-caption">
                              {{ bank_purchasing_preferences_details.purchase_purpose.length }}
                              Selected
                            </span>
                          </template>
                        </v-select>
                        <div class="font-weight-bold mt-n2 mb-2">Industry <span class="red--text">*</span></div>
                        <v-select
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_industry"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                          :items="industryMastersList"
                          multiple
                        >
                          <template v-slot:selection="{ index }">
                            <span v-if="index === 0" class="grey--text text-caption">
                              {{ bank_purchasing_preferences_details.purchase_industry.length }}
                              Selected
                            </span>
                          </template>
                        </v-select>

                        <div class="font-weight-bold mt-n2 mb-2">
                          Other characteristics related to purchasing
                          <span class="red--text">*</span>
                        </div>
                        <v-textarea
                          rows="3"
                          dense
                          outlined
                          v-model="bank_purchasing_preferences_details.purchase_other_characteristics"
                          :rules="[(v) => !!v || 'Required']"
                          class="borderRadiusVariant1"
                        ></v-textarea>
                      </v-form>
                    </v-card>
                    <v-card-actions>
                      <v-btn depressed color="primary" :loading="loadingStepper5" @click="validateStepperFiveMethod()" class="text-capitalize borderRadiusVariant2 pa-6"> Submit </v-btn>
                    </v-card-actions>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import { GetVerificationCodeForBankWithControllerOnboarding } from "@/mixins/RestAPIs/GetVerificationCodeForBankWithControllerOnboarding.js";
import { VerifyCodeAndFetchBankDetails } from "@/mixins/RestAPIs/VerifyCodeAndFetchBankDetails.js";
import { ActivateBankWithController } from "@/mixins/RestAPIs/ActivateBankWithController.js";
import { industryMaster } from "@/mixins/Masters/industryMaster.js";
import { classMaster } from "@/mixins/Masters/classMaster.js";
import { propertyMaster } from "@/mixins/Masters/propertyMaster.js";
import { purposeMaster } from "@/mixins/Masters/purposeMaster.js";
import { formatphoneNumber } from "@/mixins/Extras/FormatPhoneNumbers.js";
import { formatNumberWithCommas } from "@/mixins/Extras/FormatNumbersWithCommas.js";

import ContactUsDialog from "@/components/MainLoginPage/Dialogs/ContactUsDialog.vue";
import USStatesList from "@/JsonFiles/USStatesList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    Snackbar,
    Overlay,
    ContactUsDialog,
  },
  mixins: [
    GetVerificationCodeForBankWithControllerOnboarding,
    VerifyCodeAndFetchBankDetails,
    ActivateBankWithController,
    industryMaster,
    classMaster,
    propertyMaster,
    purposeMaster,
    formatphoneNumber,
    formatNumberWithCommas,
  ],
  data: () => ({
    intervalId: null,
    cardHeight: 0,
    ResendOTPCounter: 0,
    loginStepper: 1,
    contact_count: 0,
    currentContactIndex: -1,

    current_view: "STEPPER",
    customBackgroundColor: "#d2d2d2",

    overlay: false,
    loadingStepper1: false,
    loadingStepper2: false,
    loadingStepper5: false,
    isPwdLoginCreate: false,
    isPwdLoginConfirm: false,
    loadingActivatingAccount: false,
    ContactUsDialog: false,

    bank_details: {},
    user_details: {
      bank_contact_person_first_name: "",
      bank_contact_person_last_name: "",
      user_email_id: "",
      bank_contact_person_country_code: "+1",
      bank_contact_person_contact_number: "",
      user_bank_name: "",
      user_bank_id: "",
      user_create_password: "",
      user_confirm_password: "",
    },
    contact_details: {
      contact_first_name: "",
      contact_last_name: "",
      contact_title: "",
      contact_email_id: "",
      contact_country_code: "+1",
      contact_phone_number: "",
      contact_address_line_1: "",
      contact_address_line_2: "",
      contact_city: "",
      contact_state: "",
      contact_zip_code: "",
    },
    SnackBarComponent: {},
    currentStepperObject: {},
    currentTeamDetailsObject: {},
    verification_code_details: {
      verification_code: "",
    },
    bank_purchasing_preferences_details: {
      purchase_hold_range: [],
      purchase_project_type: "",
      purchase_property_class: "",
      purchase_purpose: "",
      purchase_industry: "",
      purchase_other_characteristics: "",
    },

    USStatesList: [],
    stepperItems: [
      {
        stepper_title: "Let's activate your DealData account",
        stepper_sub_title: "Please provide a strong password below to activate your account",
      },
      {
        stepper_title: "Please enter the code we sent to your email address",
        stepper_sub_title: "For security reasons, we have sent you a code",
      },
      {
        stepper_title: "Your Account is Activated! Please confirm your bank profile below is accurate",
        stepper_sub_title: "",
      },
      {
        stepper_title: "Please add your syndication contacts",
        stepper_sub_title: "",
      },
      {
        stepper_title: "Please add your bank's purchasing preferences",
        stepper_sub_title: "",
      },
    ],
    holdRangeItems: ["$1m - $5m", "$6m - $10m", "$11m - $25m", "$26m - $50m", "$51m - $100m", "$100m+"],
    GetAllBanksList: [],
    syndication_contact_details: [],
    GetAllCountriesList: [
      {
        country_name: "USA",
        country_dial_code: "+1",
      },
    ],
    contactTitleItems: ["SVP", "Loan Syndications"],
  }),
  watch: {
    "user_details.user_email_id"(val) {
      this.user_details.user_email_id = val.toLowerCase();
    },
    "contact_details.contact_email_id"(val) {
      if (val != null && val != undefined && val != "") {
        this.contact_details.contact_email_id = val.replace(/\s/g, "").toLowerCase();
      }
    },
  },
  computed: {
    IsValidated() {
      return this.contact_count === this.syndication_contact_details.length;
    },
  },
  async mounted() {
    this.loginStepper = 1;
    this.current_view = "CARD";
    this.cardHeight = window.innerHeight - 50;
    this.USStatesList = USStatesList;
    this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
    this.getclassMasterListMethod();
    this.getpropertyMasterListMethod();
    this.getpurposeMasterListMethod();
    this.getindustryMasterListMethod();
    console.log("check");
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ResendOTPCounterMethod() {
      this.intervalId = setInterval(() => {
        this.ResendOTPCounter--;
        if (this.ResendOTPCounter <= 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    formatPhoneNumberMethod() {
      this.contact_details.contact_phone_number = this.contact_details.contact_phone_number.replace(/[^\d]/g, "");
      if (this.contact_details.contact_phone_number.length > 3) {
        this.contact_details.contact_phone_number = `(${this.contact_details.contact_phone_number.slice(0, 3)})` + " " + this.contact_details.contact_phone_number.slice(3);
      }
      if (this.contact_details.contact_phone_number.length > 8) {
        this.contact_details.contact_phone_number = this.contact_details.contact_phone_number.slice(0, 9) + "-" + this.contact_details.contact_phone_number.slice(9);
      }
    },
    UserformatPhoneNumber() {
      this.user_details.bank_contact_person_contact_number = this.user_details.bank_contact_person_contact_number.replace(/[^\d]/g, "");
      if (this.user_details.bank_contact_person_contact_number.length > 3) {
        this.user_details.bank_contact_person_contact_number =
          `(${this.user_details.bank_contact_person_contact_number.slice(0, 3)})` + " " + this.user_details.bank_contact_person_contact_number.slice(3);
      }
      if (this.user_details.bank_contact_person_contact_number.length > 8) {
        this.user_details.bank_contact_person_contact_number = this.user_details.bank_contact_person_contact_number.slice(0, 9) + "-" + this.user_details.bank_contact_person_contact_number.slice(9);
      }
    },
    PreventSpaceMethod() {
      if (this.contact_details.contact_city != null && this.contact_details.contact_city != undefined && this.contact_details.contact_city != "") {
        this.contact_details.contact_city = this.contact_details.contact_city.trim();
      }
    },
    async enterStepOneMethod() {
      this.current_view = "STEPPER";
      this.loginStepper = 1;
      if (this.$refs.formStep1) {
        this.$refs.formStep1.reset();
      }
      setTimeout(() => {
        if (this.$route.params.InvitedMemberDetails) {
          let userDetails = atob(this.$route.params.InvitedMemberDetails).split("&");
          if (userDetails.length == 7) {
            this.user_details.bank_contact_person_first_name = userDetails[0].split("=")[1] != "undefined" && userDetails[0].split("=")[1] != "null" ? userDetails[0].split("=")[1] : "";
            this.user_details.bank_contact_person_last_name = userDetails[1].split("=")[1] != "undefined" && userDetails[1].split("=")[1] != "null" ? userDetails[1].split("=")[1] : "";
            this.user_details.user_email_id = userDetails[2].split("=")[1];
            this.user_details.user_bank_name = userDetails[3].split("=")[1];
            this.user_details.user_bank_id = userDetails[4].split("=")[1];
            this.user_details.bank_contact_person_country_code = userDetails[5].split("=")[1] != "undefined" ? userDetails[5].split("=")[1] : "+1";
            this.user_details.bank_contact_person_contact_number = userDetails[6].split("=")[1] != "undefined" ? userDetails[6].split("=")[1] : "";
            this.overlay = false;
          }
        }
      }, 500);
    },
    async validateStepperOneMethod() {
      if (this.$refs.formStep1.validate()) {
        if (this.user_details.user_create_password == this.user_details.user_confirm_password) {
          this.loadingStepper1 = true;
          console.log("HIi");
          let result = await this.GetVerificationCodeForBankWithControllerOnboardingMethod(this.user_details);
          this.loadingStepper1 = false;
          if (result.data && result.data.status == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: result.data.status_message,
            };
            this.ResendOTPCounter = 120;
            this.ResendOTPCounterMethod();
            this.loginStepper = 2;
            this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
            if (this.$refs.formStep2) {
              this.$refs.formStep2.reset();
            }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.data.errorMessage,
            };
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async validateStepperTwoMethod() {
      if (this.$refs.formStep2.validate()) {
        this.loadingStepper2 = true;
        let result = await this.VerifyCodeAndFetchBankDetailsMethod(this.user_details.user_email_id, this.verification_code_details.verification_code, this.user_details.user_bank_id);
        this.loadingStepper2 = false;
        if (result.data && result.data.status == "Success" && result.data.data && result.data.data.items) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP verified Successfully",
          };
          this.currentTeamDetailsObject = result.data.data.items[0].bank_details;
          this.loginStepper = 3;
          this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
          this.loadingActivatingAccount = true;
          setTimeout(() => {
            this.loadingActivatingAccount = false;
          }, 3000);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.data.errorMessage,
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    validateStepperThreeMethod() {
      this.loginStepper = 4;
      this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
    },
    SyndicationContactMethod(Action) {
      if (this.$refs.formStep4.validate()) {
        const isContactExisting = this.syndication_contact_details.find((contact) => contact.contact_email_id === this.contact_details.contact_email_id);

        if (!isContactExisting) {
          this.syndication_contact_details.push({ ...this.contact_details });
        }
      }

      if (Action == "BACK") {
        this.contact_count -= 1;
        this.contact_details = this.syndication_contact_details[this.contact_count];
      }
      if (Action == "FORWARD") {
        this.contact_count += 1;
        this.contact_details = this.syndication_contact_details[this.contact_count];
      }
    },
    validateStepperFourMethod(type) {
      if (this.$refs.formStep4.validate()) {
        const isContactExisting = this.syndication_contact_details.find((contact) => contact.contact_email_id === this.contact_details.contact_email_id);

        if (!isContactExisting) {
          this.syndication_contact_details.push({ ...this.contact_details });
        }
        if (type == "DELETE") {
          this.syndication_contact_details.splice(this.syndication_contact_details.indexOf(this.contact_count), 1);
          this.contact_count = this.syndication_contact_details.length - 1;

          if (this.contact_count == -1) {
            this.contact_count = 0;
          }
          this.contact_details = {
            contact_first_name: "",
            contact_last_name: "",
            contact_title: "",
            contact_email_id: "",
            contact_country_code: "+1",
            contact_phone_number: "",
            contact_address_line_1: "",
            contact_address_line_2: "",
            contact_city: "",
            contact_state: "",
            contact_zip_code: "",
          };
          this.$refs.formStep4.reset();
          setTimeout(() => {
            this.contact_details.contact_country_code = "+1";
            this.contact_details = this.syndication_contact_details[this.contact_count];
            this.$forceUpdate();
          }, 1000);
        }
        if (type == "STAY") {
          this.contact_details = {
            contact_first_name: "",
            contact_last_name: "",
            contact_title: "",
            contact_email_id: "",
            contact_country_code: "+1",
            contact_phone_number: "",
            contact_address_line_1: "",
            contact_address_line_2: "",
            contact_city: "",
            contact_state: "",
            contact_zip_code: "",
          };
          this.$refs.formStep4.reset();
          setTimeout(() => {
            this.contact_details.contact_country_code = "+1";
          }, 1000);
          this.contact_count += 1;
        }
        if (type == "NEXT") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Syndication Contacts Added successfully",
          };
          this.loginStepper = 5;
          this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
        }
      }
    },
    async validateStepperFiveMethod() {
      if (this.$refs.formStep5.validate()) {
        this.loadingStepper5 = true;
        let result = await this.ActivateBankWithControllerMethod(this.user_details, this.user_details.user_bank_id, this.syndication_contact_details, this.bank_purchasing_preferences_details);

        if (result.data && result.data.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: result.data.status_message,
          };
          let sign_in = {
            user_email_id: this.user_details.user_email_id,
            user_password: this.user_details.user_create_password,
          };
          this.signInMethod(sign_in);
          this.loadingStepper5 = false;
        } else {
          this.loadingStepper5 = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.data.errorMessage,
          };
        }
      } else {
        this.loadingStepper5 = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
      this.loadingStepper5 = false;
    },
    async signInMethod(sign_in) {
      try {
        this.loadingStepper5 = true;
        this.loadingSignIn = true;
        this.qrCodeRequired = false;
        this.userObject = await Auth.signIn(sign_in.user_email_id, sign_in.user_password);
        this.$store.commit("SET_USEREMAIL", sign_in.user_email_id);
        if (this.userObject.challengeName === "MFA_SETUP") {
          const res = await Auth.setupTOTP(this.userObject);
          this.qrCodeURL = "otpauth://totp/AWSCognito:" + sign_in.user_email_id + "?secret=" + res + "&issuer=Cognito";
          this.qrCodeRequired = true;
        } else if (this.userObject.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.qrCodeRequired = false;
        } else {
          this.pushToHomeRouteMethod();
        }
        this.loadingSignIn = false;
      } catch (error) {
        this.loadingStepper5 = false;
        this.loadingSignIn = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    pushToHomeRouteMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      setTimeout(() => {
        this.loadingStepper5 = false;
        this.$router.push("/MainHomePages/LandingPage");
      }, 1000);
    },
    ContactUsDialogEmit() {
      this.ContactUsDialog = false;
    },
  },
};
</script>

<style>
.fill-height {
  height: 100vh;
}
</style>
