import axios from "axios";

export const GetVerifiedTrustedDevice = {
  data: () => ({
    GetTrustedDeviceUserEmailIds: [],
  }),
  methods: {
    async GetVerifiedTrustedDeviceMethod(browser_id) {
      try {
        let data = JSON.stringify({
          browser_id,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicVerifyTrustedDeviceRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        let result = await axios(config);
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.IsDeviceTrusted = true;
          this.GetTrustedDeviceUserEmailIds = result.data.data.map(
            (item) => item.user_email_id
          );
        }
      } catch (error) {
        this.IsDeviceTrusted = false;
        console.log("Error In LIST API CLass", error);
      }
    },
    getDynamicVerifyTrustedDeviceRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/checkuserstrusteddevicebrowsers"
        : window.location.hostname === "test.commdealdata.com"
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/checkuserstrusteddevicebrowsers"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/checkuserstrusteddevicebrowsers"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/checkuserstrusteddevicebrowsers";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com"
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
