import axios from "axios";

export const AddTrustedDeviceUser = {
  data: () => ({}),
  methods: {
    async AddTrustedDeviceUserMethod(browser_id, user_email_id) {
      try {
        let data = JSON.stringify({
          browser_id,
          user_email_id,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicAddTrustedDeviceRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        let result = await axios(config);
        if (result.data.status == "Success") {
          return result.data;
        }
      } catch (error) {
        console.log("Error In LIST API CLass", error);
      }
    },
    getDynamicAddTrustedDeviceRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/addtrusteddeviceuserbrowser"
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/addtrusteddeviceuserbrowser"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/addtrusteddeviceuserbrowser"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/addtrusteddeviceuserbrowser";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW" 
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com"
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
