import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    TokenDetails: {},
    GetCurrentUser: {},
    InvitedMemberDetails: {},
    session_status: "",
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_route_params: (state) => {
      return state.InvitedMemberDetails;
    },
    get_token_details: (state) => {
      return state.TokenDetails;
    },
    get_user_session_status: (state) => {
      return state.session_status;
    },
  },
  mutations: {
    SET_USEREMAIL(state, EmailID) {
      state.EmailID = EmailID;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_ROUTE_PARAMS(state, InvitedMemberDetails) {
      state.InvitedMemberDetails = InvitedMemberDetails;
    },
    SET_TOKEN_DETAILS(state, TokenDetails) {
      state.TokenDetails = TokenDetails;
    },
    SET_USER_SESSION_STATUS(state, session_status) {
      state.session_status = session_status;
    },
  },
  actions: {},
  modules: {},
});
